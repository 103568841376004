import * as React from "react";
import styled, { ThemeProvider } from "styled-components";
import { lightTheme } from "../components/theme";
import MetaComponent from "../components/MetaComponent";
import HeroGeneral from "../components/heroGeneral";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import KeyfiCircle from "../images/keyfiCircle.svg";
import CoreIcons from "../images/coreIcons.svg";
import MacMockupPro from "../images/macMockupPro.png";
import heroBackgound from "../images/about_newbg.jpg";
import heroBackgoundMobile from "../images/about_newbg_mobile.jpg";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import { graphql } from "gatsby";

import "./about.css";
import "./index.css";

const Wrapper = styled.div`
  background: white;
  overflow: hidden;
  position: relative;
`;

const UserGuideWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={lightTheme}>
      <MetaComponent pageName="About" />
      <Wrapper>
        <Menu activeMenu="about" />
        <HeroGeneral
          title={t("homepage.footer.company")}
          background={heroBackgound}
          backgoundmb={heroBackgoundMobile}
        />
        <div className="platformContentWrap">
          <section className="aboutPlatform">
            <div className="platformLeft">
              <h1 className="platformTitle">{t("about.keyfi.title")}</h1>
              <p className="platformParagraph">{t("about.keyfi.text1")}</p>
              <p className="platformParagraph">{t("about.keyfi.text2")}</p>
            </div>
            <div className="platformRight">
              <img src={KeyfiCircle} alt="KeyFi Circle" />
            </div>
          </section>

          <section className="aboutCore">
            <div className="platformLeft">
              <h1 className="platformTitleCore">
                KeyFi {t("about.core.title")}
              </h1>
              <p className="platformParagraph">{t("about.core.text")}</p>
            </div>
            <div className="platformRight">
              <img src={CoreIcons} alt="KeyFi Core" />
            </div>
          </section>

          {/* const proVersion = {
  version: "pro",
  bg: "proBg",
  text: "proText",
  cta: "ctaPro",
  ctaText: "Learn More",
  title:
    "Designed for sophisticated users who want fine grain control over their DeFi assets.",
  link: "/pro",
  listItems: [
    "Everything available in KeyFi, plus...",
    "Build custom DeFi strategies using the new innovative Strategy Manager",
    "Quotes and research spanning 7,000+ tokens, 400+ exchanges, and 50+ unique news sources",
    "Access to CeFi earn & borrow platforms such as Nexo",
    "Unlimited, custom portfolio alerts to Telegram, email, and SMS",
    "Discover new tokens based on algorithmic analysis of your holdings",
  ],
}; */}

          <section className="aboutPro">
            <div className="platformLeft aboutProLeft">
              <h1 className="platformTitleCore">
                KeyFi {t("about.pro.title")} <span>(coming soon)</span>
              </h1>
              <p className="platformParagraph">{t("about.pro.text")}</p>
              <ul className="platformList">
                <li className="platformListItem">{t("about.pro.text1")}</li>
                <li className="platformListItem">{t("about.pro.text2")}</li>
                <li className="platformListItem">{t("about.pro.text3")}</li>
                <li className="platformListItem">{t("about.pro.text4")}</li>
                <li className="platformListItem">{t("about.pro.text5")}</li>
                <li className="platformListItem">{t("about.pro.text6")}</li>
              </ul>
            </div>

            <img
              className="macMockup"
              src={MacMockupPro}
              alt="Macbook Mockup with KeyFi dashboard"
            />
          </section>
          <UserGuideWrapper>
            <a
              href="https://docs.keyfi.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button primary>{t("homepage.footer.userGuide")}</Button>
            </a>
          </UserGuideWrapper>
        </div>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default AboutPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
