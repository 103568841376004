import React from "react";
import styled from "styled-components";
import "./heroGeneral.css";

const HeroWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 338px;
  padding-top: 149px;
  width: 100%;
  background-image: url(${(props) => props.img});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;

  @media (max-width: 576px) {
    background-image: url(${(props) => props.backgoundmb});
    background-repeat: no-repeat;
    background-size: cover;
    height: 240px;

    h1 {
      margin-top: -44px !important;
    }
  }

  @media (min-width: 576px) and (max-width: 768px) {
    background-image: url(${(props) => props.backgoundmb});
    background-repeat: no-repeat;
    background-size: cover;
    height: 300px;
    h1 {
      margin-top: -24px;
    }
  }
`;

export default function HeroGeneral({ title, background, backgoundmb }) {
  return (
    <HeroWrapper img={background} backgoundmb={backgoundmb}>
      <div className="heroGeneralText">
        <h1>{title}</h1>
      </div>
    </HeroWrapper>
  );
}
